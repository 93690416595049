import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import i18next from "i18next";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";

import App from "./App";
import store from "./redux/store";
import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import common_es from "./translations/es/common.json";
import common_fr from "./translations/fr/common.json";
import common_in from "./translations/in/common.json";
import common_ja from "./translations/ja/common.json";
import common_ko from "./translations/ko/common.json";
import common_ms from "./translations/ms/common.json";
import common_pt from "./translations/pt/common.json";
import common_ru from "./translations/ru/common.json";
import common_th from "./translations/th/common.json";
import common_vi from "./translations/vi/common.json";
import common_zh from "./translations/zh/common.json";

// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-react-app-with-react-i18next
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: localStorage.getItem("language") || "en", // language to use
  resources: {
    en: { common: common_en }, // 'common' is our custom namespace
    de: { common: common_de },
    es: { common: common_es },
    fr: { common: common_fr },
    in: { common: common_in },
    ja: { common: common_ja },
    ko: { common: common_ko },
    ms: { common: common_ms },
    pt: { common: common_pt },
    ru: { common: common_ru },
    th: { common: common_th },
    vi: { common: common_vi },
    zh: { common: common_zh },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById("root"),
);
