import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";

import { setSnackbarErrorMessage } from "./snackbarSlice";

export const loadAssetsByType = createAsyncThunk("assets/loadAssetsByType", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const axiosInstance = createAxiosInstance(thunkAPI.dispatch);
    axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";

    const result = await axiosInstance.get(`/cms/brands/${activeBrandId}/assets/${data["assetType"]}`);

    return { assets: result.data };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain asset list";
    const customError = {
      name: "Asset List Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const loadAsset = createAsyncThunk("assets/loadAsset", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const axiosInstance = createAxiosInstance(thunkAPI.dispatch);

    axiosInstance.defaults.headers["Content-Type"] = null;
    axiosInstance.defaults.headers["Accept"] = "*/*";

    const result = await axiosInstance.get(
      `/cms/brands/${activeBrandId}/assets/${data["assetType"]}/${data["assetId"]}`,
      { responseType: "blob" },
    ); // <======

    return { asset: result.data, assetId: data["assetId"] };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain asset";
    const customError = {
      name: "Asset Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const deleteAsset = createAsyncThunk("assets/deleteAsset", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const axiosInstance = createAxiosInstance(thunkAPI.dispatch);

    const result = await createAxiosInstance(thunkAPI.dispatch).delete(
      `/cms/brands/${activeBrandId}/assets/${data["assetId"]}`,
    );

    thunkAPI.dispatch(loadAssetsByType({ assetType: data["assetType"] }));

    return {};
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to delete asset";
    const customError = {
      name: "Asset Delete Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const createAsset = createAsyncThunk("assets/createAsset", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const axiosInstance = createAxiosInstance(thunkAPI.dispatch);
    axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";

    const bodyFormData = new FormData();
    bodyFormData.append("fileDescription", data.fileDescription.trim());
    bodyFormData.append("tags", data.tags.join(","));
    bodyFormData.append("file", data.fileObject);

    const result = await axiosInstance.post(`/cms/brands/${activeBrandId}/assets/${data["assetType"]}`, bodyFormData);

    thunkAPI.dispatch(loadAssetsByType({ assetType: data["assetType"] }));

    return {};
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to save asset";
    const customError = {
      name: "Asset List Save Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const updateAsset = createAsyncThunk("assets/updateAsset", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const axiosInstance = createAxiosInstance(thunkAPI.dispatch);
    axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";

    const bodyFormData = new FormData();
    bodyFormData.append("fileDescription", data.fileDescription.trim());
    bodyFormData.append("tags", data.tags.join(","));

    const result = await axiosInstance.put(
      `/cms/brands/${activeBrandId}/assets/${data["assetType"]}/${data["assetId"]}`,
      bodyFormData,
    );

    thunkAPI.dispatch(loadAssetsByType({ assetType: data["assetType"] }));

    return {};
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to save asset";
    const customError = {
      name: "Asset List Save Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

const assetSlice = createSlice({
  name: "assets",
  initialState: {
    assets: null,
    activeAsset: null, // deprecated, try to use the cachedAssets instead...
    cachedAssets: {},
    loading: false,
    saving: false,
    deleting: false,
    error: null,
  },
  // reducers actions
  reducers: {
    clearActiveAsset: {
      reducer(state, { payload }) {
        state.activeAsset = null;
      },
      prepare(brandId) {
        return {
          payload: {},
        };
      },
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [loadAssetsByType.pending]: (state) => {
      state.loading = true;
      state.assets = null;
      state.error = null;
    },
    [loadAssetsByType.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [loadAssetsByType.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.assets = action.payload.assets;
    },
    [loadAsset.pending]: (state) => {
      state.loading = true;
      state.activeAsset = null;
      state.error = null;
    },
    [loadAsset.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [loadAsset.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.activeAsset = action.payload.asset;
      state.cachedAssets[action.payload.assetId] = action.payload.asset;
    },
    [deleteAsset.pending]: (state) => {
      state.deleting = true;
      state.error = null;
    },
    [deleteAsset.rejected]: (state, action) => {
      state.deleting = false;
      state.error = action.error.message;
    },
    [deleteAsset.fulfilled]: (state, action) => {
      state.deleting = false;
      state.error = null;
    },
    [createAsset.pending]: (state) => {
      state.saving = true;
      state.error = null;
    },
    [createAsset.rejected]: (state, action) => {
      state.saving = false;
      state.error = action.error.message;
    },
    [createAsset.fulfilled]: (state, action) => {
      state.saving = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = assetSlice;
export const { clearActiveAsset } = actions;
export default reducer;
