import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";

import { deleteAsset } from "./assetSlice";
import { setSnackbarErrorMessage } from "./snackbarSlice";

export const loadWidgetsByType = createAsyncThunk("widgets/loadWidgetsByType", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;
    const widgetType = data["widgetType"];

    const result = await createAxiosInstance(thunkAPI.dispatch).get(
      `/cms/brands/${activeBrandId}/widgets/${widgetType}`,
    );

    const processedData = result.data.map((widget) => ({ ...widget.data, id: widget.id }));

    return { widgets: processedData, widgetType };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain widget details";
    const customError = {
      name: "Widget List Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const createWidget = createAsyncThunk("widgets/createWidget", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const widgetType = data["widgetType"];

    const bodyData = { ...data };
    if (bodyData["description"]) {
      // make sure the description is trimmed
      bodyData["description"] = bodyData["description"].trim();
    }
    delete bodyData["widgetType"];

    const result = await createAxiosInstance(thunkAPI.dispatch).post(
      `/cms/brands/${activeBrandId}/widgets/${widgetType}`,
      bodyData,
    );

    thunkAPI.dispatch(loadWidgetsByType({ widgetType }));

    return {};
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to save widget";
    const customError = {
      name: "Widget Save Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const updateWidget = createAsyncThunk("widgets/updateWidget", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const widgetType = data["widgetType"];
    const widgetId = data["id"];

    const bodyData = { ...data };
    if (bodyData["description"]) {
      // make sure the description is trimmed
      bodyData["description"] = bodyData["description"].trim();
    }
    delete bodyData["widgetType"];
    delete bodyData["id"];

    const result = await createAxiosInstance(thunkAPI.dispatch).put(
      `/cms/brands/${activeBrandId}/widgets/${widgetType}/${widgetId}`,
      bodyData,
    );

    thunkAPI.dispatch(loadWidgetsByType({ widgetType }));

    return {};
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to save widget";
    const customError = {
      name: "Widget Save Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const deleteWidget = createAsyncThunk("widgets/deleteWidget", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const widgetType = data["widgetType"];
    const widgetId = data["id"];

    await createAxiosInstance(thunkAPI.dispatch).delete(`/cms/brands/${activeBrandId}/widgets/${widgetId}`);

    thunkAPI.dispatch(loadWidgetsByType({ widgetType }));

    return {};
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to delete widget";
    const customError = {
      name: "Widget Delete Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const loadWidgetsByAsset = createAsyncThunk("widgets/loadWidgetsUsingAsset", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;
    const assetId = data["assetId"];

    const result = await createAxiosInstance(thunkAPI.dispatch).get(
      `/cms/brands/${activeBrandId}/widgets/assetsearch/${assetId}`,
    );

    return { assetId, widgets: result.data };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain widget by asset";
    const customError = {
      name: "Widget List Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

const widgetSlice = createSlice({
  name: "widgets",
  initialState: {
    widgets: {},
    widgetByAsset: {},
    loading: false,
    saving: false,
    deleting: false,
    error: null,
  },
  // reducers actions
  reducers: {
    clearWidgets: {
      reducer(state, { payload }) {
        state.widgets = {};
      },
      prepare() {
        return {
          payload: {},
        };
      },
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [loadWidgetsByType.pending]: (state, action) => {
      const widgetType = action.meta.arg.widgetType;

      state.loading = true;
      delete state.widgets[widgetType];
      state.error = null;
    },
    [loadWidgetsByType.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [loadWidgetsByType.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.widgets[action.payload.widgetType] = action.payload.widgets;
    },
    [createWidget.pending]: (state) => {
      state.saving = true;
      state.error = null;
    },
    [createWidget.rejected]: (state, action) => {
      state.saving = false;
      state.error = action.error.message;
    },
    [createWidget.fulfilled]: (state, action) => {
      state.saving = false;
      state.error = null;
    },
    [updateWidget.pending]: (state) => {
      state.saving = true;
      state.error = null;
    },
    [updateWidget.rejected]: (state, action) => {
      state.saving = false;
      state.error = action.error.message;
    },
    [updateWidget.fulfilled]: (state, action) => {
      state.saving = false;
      state.error = null;
    },
    [deleteWidget.pending]: (state) => {
      state.deleting = true;
      state.error = null;
    },
    [deleteWidget.rejected]: (state, action) => {
      state.deleting = false;
      state.error = action.error.message;
    },
    [deleteWidget.fulfilled]: (state, action) => {
      state.deleting = false;
      state.error = null;
    },
    [loadWidgetsByAsset.pending]: (state, action) => {
      const assetId = action.meta.arg.assetId;
      delete state.widgetByAsset[assetId];
    },
    [loadWidgetsByAsset.rejected]: (state, action) => {
      //
    },
    [loadWidgetsByAsset.fulfilled]: (state, action) => {
      state.widgetByAsset[action.payload.assetId] = action.payload.widgets;
    },
  },
});

const { actions, reducer } = widgetSlice;
export const { clearWidgets } = actions;
export default reducer;
