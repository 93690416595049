import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import AuthLayout from "../layouts/Auth";
import DashboardLayout from "../layouts/Dashboard";
import SignIn from "../pages/auth/cms/SignIn";
import Page404 from "../pages/auth/Page404";

import { dashboardLayoutRoutes, authLayoutRoutes } from "./index";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, component: Component, path }, index) =>
    children ? (
      // Route item with children
      children.map(({ component: Component, path }, index) => (
        <Route
          exact
          key={index}
          path={path}
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        exact
        key={index}
        path={path}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    ),
  );

const Routes = ({ loggedIn }) => {
  const permissions = useSelector((state) => state.permission.permissions);

  return (
    <Router>
      {!loggedIn ? (
        <>
          <Route
            exact
            key="0"
            path="/auth/sign-in"
            render={(props) => (
              <AuthLayout>
                <SignIn />
              </AuthLayout>
            )}
          />
          <Redirect to={{ pathname: "/auth/sign-in" }} />
        </>
      ) : (
        <Switch>
          {childRoutes(DashboardLayout, dashboardLayoutRoutes(permissions))}
          {childRoutes(AuthLayout, authLayoutRoutes)}
          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      )}
    </Router>
  );
};

export default Routes;
