import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";

import { setSnackbarErrorMessage } from "./snackbarSlice";

export const loadLeagues = createAsyncThunk("league/loadLeagues", async (data, thunkAPI) => {
  try {
    const result = await createAxiosInstance(thunkAPI.dispatch).get(`/cms/leagues`);
    return { leagueData: result.data };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain event details";
    const customError = {
      name: "Event list Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

const leagueSlice = createSlice({
  name: "league",
  initialState: {
    leagueData: {},
    loading: false,
    error: null,
  },
  // reducers actions
  reducers: {},
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [loadLeagues.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loadLeagues.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [loadLeagues.fulfilled]: (state, action) => {
      state.loading = false;
      state.leagueData = action.payload.leagueData;
    },
  },
});
const { actions, reducer } = leagueSlice;
// export const { logout, setActiveBrand } = actions
export default reducer;
