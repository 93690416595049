import {
  AppBar as MuiAppBar,
  Badge,
  Card as MuiCard,
  Grid,
  Hidden,
  IconButton as MuiIconButton,
  InputBase,
  List,
  ListItem,
  ListItemText as MuiListItemText,
  Menu,
  MenuItem,
  Paper as MuiPaper,
  Toolbar,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import i18next from "i18next";
import { darken } from "polished";
import React, { useState } from "react";
import { Power } from "react-feather";
import { connect } from "react-redux";
import styled, { withTheme } from "styled-components";

import { logout, setActiveBrand } from "../redux/slices/authSlice";
import { getLanguageName } from "../utils/language-utils";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ListItemText = styled(MuiListItemText)(spacing);

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

class BrandMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      selectedIndex: props.brands.findIndex((brand) => brand.id === props.activeBrandId),
    };
  }

  handleClickListItem = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (event, index) => {
    this.setState({ selectedIndex: index, anchorEl: null });
    this.props.setActiveBrand(this.props.brands[index].id);
    window.location.reload(false);
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <>
        <List component="nav">
          <ListItem
            button
            aria-controls="lock-menu"
            aria-haspopup="true"
            aria-label="Brand"
            onClick={this.handleClickListItem}
          >
            <ListItemText primary="Brand" secondary={this.props.brands[this.state.selectedIndex].description} />
          </ListItem>
        </List>
        <Menu anchorEl={anchorEl} id="lock-menu" open={Boolean(anchorEl)} onClose={this.handleClose}>
          {this.props.brands.map((option, index) => (
            <MenuItem
              key={option.id}
              selected={index === this.state.selectedIndex}
              onClick={(event) => this.handleMenuItemClick(event, index)}
            >
              {option.description}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

const brandMenuMapStateToProps = (state) => ({ activeBrandId: state.auth.activeBrandId, brands: state.auth.brands });
BrandMenu = connect(brandMenuMapStateToProps, { setActiveBrand })(BrandMenu);

function LanguageMenu() {
  const [anchorMenu, setAnchorMenu] = useState(null);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const changeLanguageMenu = (lang) => {
    closeMenu();

    localStorage.setItem("language", lang);
    i18next.changeLanguage(lang);
    window.location.reload(false);
  };

  const getFlag = () => {
    let flag = <Flag alt="English" src="/static/img/flags/gb.png" />;

    const lang = localStorage.getItem("language");
    switch (lang) {
      case "de":
        flag = <Flag alt={getLanguageName()} src="/static/img/flags/de.png" />;
        break;
      case "es":
        flag = <Flag alt={getLanguageName()} src="/static/img/flags/es.png" />;
        break;
      case "fr":
        flag = <Flag alt={getLanguageName()} src="/static/img/flags/fr.png" />;
        break;
      case "in":
        flag = <Flag alt={getLanguageName()} src="/static/img/flags/id.png" />;
        break;
      case "ja":
        flag = <Flag alt={getLanguageName()} src="/static/img/flags/jp.png" />;
        break;
      case "ko":
        flag = <Flag alt={getLanguageName()} src="/static/img/flags/kr.png" />;
        break;
      case "ms":
        flag = <Flag alt={getLanguageName()} src="/static/img/flags/my.png" />;
        break;
      case "pt":
        flag = <Flag alt={getLanguageName()} src="/static/img/flags/pt.png" />;
        break;
      case "ru":
        flag = <Flag alt={getLanguageName()} src="/static/img/flags/ru.png" />;
        break;
      case "th":
        flag = <Flag alt={getLanguageName()} src="/static/img/flags/th.png" />;
        break;
      case "vi":
        flag = <Flag alt={getLanguageName()} src="/static/img/flags/vn.png" />;
        break;
      case "zh":
        flag = <Flag alt={getLanguageName()} src="/static/img/flags/cn.png" />;
        break;
      default:
        break;
    }

    return flag;
  };

  const isSelectedLanguage = (lang) => localStorage.getItem("language") === lang;

  return (
    <>
      <IconButton
        aria-haspopup="true"
        aria-owns={anchorMenu ? "menu-appbar" : undefined}
        color="inherit"
        onClick={toggleMenu}
      >
        {getFlag()}
      </IconButton>
      <Menu anchorEl={anchorMenu} id="menu-appbar" open={Boolean(anchorMenu)} onClose={closeMenu}>
        <MenuItem selected={isSelectedLanguage("en")} onClick={() => changeLanguageMenu("en")}>
          {getLanguageName("en")}
        </MenuItem>
        <MenuItem selected={isSelectedLanguage("fr")} onClick={() => changeLanguageMenu("fr")}>
          {getLanguageName("fr")}
        </MenuItem>
        <MenuItem selected={isSelectedLanguage("de")} onClick={() => changeLanguageMenu("de")}>
          {getLanguageName("de")}
        </MenuItem>
        <MenuItem selected={isSelectedLanguage("es")} onClick={() => changeLanguageMenu("es")}>
          {getLanguageName("es")}
        </MenuItem>
        <MenuItem selected={isSelectedLanguage("in")} onClick={() => changeLanguageMenu("in")}>
          {getLanguageName("in")}
        </MenuItem>
        <MenuItem selected={isSelectedLanguage("ja")} onClick={() => changeLanguageMenu("ja")}>
          {getLanguageName("ja")}
        </MenuItem>
        <MenuItem selected={isSelectedLanguage("ko")} onClick={() => changeLanguageMenu("ko")}>
          {getLanguageName("ko")}
        </MenuItem>
        <MenuItem selected={isSelectedLanguage("ms")} onClick={() => changeLanguageMenu("ms")}>
          {getLanguageName("ms")}
        </MenuItem>
        <MenuItem selected={isSelectedLanguage("pt")} onClick={() => changeLanguageMenu("pt")}>
          {getLanguageName("pt")}
        </MenuItem>
        <MenuItem selected={isSelectedLanguage("ru")} onClick={() => changeLanguageMenu("ru")}>
          {getLanguageName("ru")}
        </MenuItem>
        <MenuItem selected={isSelectedLanguage("th")} onClick={() => changeLanguageMenu("th")}>
          {getLanguageName("th")}
        </MenuItem>
        <MenuItem selected={isSelectedLanguage("vi")} onClick={() => changeLanguageMenu("vi")}>
          {getLanguageName("vi")}
        </MenuItem>
        <MenuItem selected={isSelectedLanguage("zh")} onClick={() => changeLanguageMenu("zh")}>
          {getLanguageName("zh")}
        </MenuItem>
      </Menu>
    </>
  );
}

let UserMenu = function ({ dispatch }) {
  const [anchorMenu, setAnchorMenu] = useState(null);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const logoutMenu = () => {
    closeMenu();
    dispatch(logout());
  };

  return (
    <>
      <IconButton
        aria-haspopup="true"
        aria-owns={anchorMenu ? "menu-appbar" : undefined}
        color="inherit"
        onClick={toggleMenu}
      >
        <Power />
      </IconButton>
      <Menu anchorEl={anchorMenu} id="menu-appbar" open={Boolean(anchorMenu)} onClose={closeMenu}>
        <MenuItem onClick={logoutMenu}>Sign out</MenuItem>
      </Menu>
    </>
  );
};

UserMenu = connect()(UserMenu);

const Header = ({ onDrawerToggle }) => (
  <>
    <AppBar elevation={0} position="sticky">
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton aria-label="Open drawer" color="inherit" onClick={onDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>

          <Grid item xs />

          <Grid item>
            <BrandMenu />
          </Grid>
          <Grid item>
            <LanguageMenu />
            <UserMenu />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </>
);

export default connect()(withTheme(Header));
