import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";

import { loadPermissions } from "./permissionSlice";

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    open: false,
    errorMessage: null,
  },
  // reducers actions
  reducers: {
    closeSnackbar: {
      reducer(state, { payload }) {
        state.open = false;
        state.errorMessage = null;
      },
      prepare() {
        return {
          payload: {},
        };
      },
    },
    setSnackbarErrorMessage: {
      reducer(state, { payload }) {
        state.open = true;
        state.errorMessage = payload.errorMessage;
      },
      prepare(errorMessage) {
        return {
          payload: { errorMessage },
        };
      },
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
  },
});
const { actions, reducer } = snackbarSlice;
export const { closeSnackbar, setSnackbarErrorMessage } = actions;
export default reducer;
