import DateFnsUtils from "@date-io/date-fns";
import { Snackbar } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

import { loadPermissions } from "./redux/slices/permissionSlice";
import { closeSnackbar } from "./redux/slices/snackbarSlice";
import Routes from "./routes/Routes";
import maTheme from "./theme";

const vertical = "top";
const horizontal = "right";

function App() {
  const currentTheme = useSelector((state) => state.theme.currentTheme);

  const loggedIn = useSelector((state) => state.auth.loggedIn);

  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn) {
      dispatch(loadPermissions());
    }
  }, [loggedIn]);

  // Manage snackbars
  const openSnackbar = useSelector((state) => state.snackbar.open);
  const snackbarErrorMessage = useSelector((state) => state.snackbar.errorMessage);
  useEffect(() => {
    if (openSnackbar) {
      const timer = setTimeout(() => {
        dispatch(closeSnackbar());
      }, 5000);
      return () => clearTimeout(timer);
    }

    return undefined;
  }, [openSnackbar]);

  return (
    <>
      <Helmet defaultTitle="P8 CMS - Advanced CMS and Admin dashboard" titleTemplate="%s | P8 CMS" />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[currentTheme]}>
            <ThemeProvider theme={maTheme[currentTheme]}>
              <Snackbar
                ContentProps={{
                  "aria-describedby": "message-id",
                }}
                anchorOrigin={{ horizontal, vertical }}
                message={<span id="message-id">{snackbarErrorMessage}</span>}
                open={openSnackbar}
              />
              <Routes loggedIn={loggedIn} />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </>
  );
}

export default App;
