import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";

import { setSnackbarErrorMessage } from "./snackbarSlice";

export const searchEvents = createAsyncThunk("sdc/searchEvents", async (data, thunkAPI) => {
  try {
    // TODO - return all markets. TODO - return all virtual sports
    const result = await createAxiosInstance(thunkAPI.dispatch).get(
      `/cms/sdc/events/${data["searchToken"]}?lineId=2&originId=3`,
    ); // todo - do not hardcode line and origin

    const eventData = {};
    Object.entries(result.data.items).forEach((item) => {
      const type = item[0].substr(0, 1);
      if (type === "e") {
        // prematch events only
        const id = parseInt(item[0].substr(1));
        const itemData = item[1];

        // Find out the parent league / tournament
        const tournamentData = result.data.items[itemData.parent];

        // Find out the parent country / category
        const categoryData = result.data.items[tournamentData.parent];

        // Find out the parent sport too!
        const sportData = result.data.items[categoryData.parent];

        eventData[id] = {
          eventId: id,
          eventDescription: itemData.desc,
          description: `${sportData.desc} - ${categoryData.desc} - ${tournamentData.desc} - ${itemData.desc}`,
          tournamentId: Number(itemData.parent.slice(1, itemData.parent.length)),
          categoryId: Number(tournamentData.parent.slice(1, tournamentData.parent.length)),
          tournamentDescription: tournamentData.desc,
          categoryDescription: categoryData.desc,
          sportCode: sportData.code,
          sportDescription: sportData.desc,
        };
      }
    });

    return { eventData };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain event details";
    const customError = {
      name: "Event list Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const loadEventMarkets = createAsyncThunk("sdc/loadEventMarkets", async (data, thunkAPI) => {
  try {
    // TODO - return all markets. TODO - return all virtual sports
    const result = await createAxiosInstance(thunkAPI.dispatch).get(
      `/cms/sdc/e${data["eventId"]}?lineId=2&originId=3&ext=1`,
    ); // todo - do not hardcode line and origin

    const marketData = {};
    Object.entries(result.data.items).forEach((item) => {
      const type = item[0].substr(0, 1);
      if (type === "m") {
        // prematch events only
        const id = parseInt(item[0].substr(1));
        const itemData = item[1];

        marketData[id] = {
          marketId: id,
          description: itemData.desc,
          marketDescription: `${itemData.desc} - ${itemData.period}`,
        };
      }
    });

    return { marketData };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain market details";
    const customError = {
      name: "Market list Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

const sdcSlice = createSlice({
  name: "sdc",
  initialState: {
    eventData: {},
    searchLoading: false,
    eventMarkets: {},
    eventMarketLoading: false,
    error: null,
  },
  // reducers actions
  reducers: {},
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [searchEvents.pending]: (state) => {
      state.searchLoading = true;
      state.eventData = null;
      state.error = null;
    },
    [searchEvents.rejected]: (state, action) => {
      state.searchLoading = false;
      state.error = action.error.message;
    },
    [searchEvents.fulfilled]: (state, action) => {
      state.searchLoading = false;
      state.eventData = action.payload.eventData;
    },
    [loadEventMarkets.pending]: (state) => {
      state.eventMarketLoading = true;
      state.eventMarkets = null;
      state.error = null;
    },
    [loadEventMarkets.rejected]: (state, action) => {
      state.eventMarketLoading = false;
      state.error = action.error.message;
    },
    [loadEventMarkets.fulfilled]: (state, action) => {
      state.searchLoading = false;
      state.eventMarkets = action.payload.marketData;
    },
  },
});
const { actions, reducer } = sdcSlice;
// export const { logout, setActiveBrand } = actions
export default reducer;
