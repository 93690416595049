import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";

import { setSnackbarErrorMessage } from "./snackbarSlice";

export const loadBrandAndOriginData = createAsyncThunk("brand/loadBrandsOriginsAndLines", async (data, thunkAPI) => {
  try {
    const result = await createAxiosInstance(thunkAPI.dispatch).get(
      `/cms/accounts/${localStorage.getItem("accountId")}/brands/${data["brandId"]}/originlinedetails`,
    );

    return {
      brand: result.data["brand"],
      origins: result.data["origins"],
      originLines: result.data["originAndLines"],
    };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain brand details";
    const customError = {
      name: "Brand Info Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

const brandSlice = createSlice({
  name: "brand",
  initialState: {
    brand: null,
    origins: null,
    originLines: null,
    loading: false,
    error: null,
  },
  // reducers actions
  reducers: {},
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [loadBrandAndOriginData.pending]: (state) => {
      state.loading = true;
      state.brand = null;
      state.origins = null;
      state.originLines = null;
      state.error = null;
    },
    [loadBrandAndOriginData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [loadBrandAndOriginData.fulfilled]: (state, action) => {
      state.loading = false;
      state.brand = action.payload.brand;
      state.origins = action.payload.origins;
      state.originLines = action.payload.originLines;
    },
  },
});
const { actions, reducer } = brandSlice;
// export const { logout, setActiveBrand } = actions
export default reducer;
