import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    currentTheme: 0,
  },
  // reducers actions
  reducers: {
    // create action
    setTheme: (state, action) => ({
      ...state,
      currentTheme: action.payload,
    }),
  },
});
const { actions, reducer } = themeSlice;
export const { setTheme } = actions;
export default reducer;
