import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";

import { loadPermissions } from "./permissionSlice";

export const login = createAsyncThunk("auth/login", async (data, thunkAPI) => {
  try {
    const axios = createAxiosInstance(thunkAPI.dispatch);
    const loginResult = await axios.post(`/cms/login`, {
      username: data["username"],
      password: data["password"],
    });
    localStorage.setItem("authToken", loginResult.data["hsToken"]);
    localStorage.setItem("accountId", loginResult.data["id"]);
    localStorage.setItem("username", loginResult.data["username"]);

    const brandsResult = await createAxiosInstance(thunkAPI.dispatch).get(
      `/cms/accounts/${loginResult.data["id"]}/brands`,
    );

    const brands = brandsResult.data.map((brand) => ({
      id: brand.id,
      description: brand.description,
    }));
    localStorage.setItem("brands", JSON.stringify(brands));
    localStorage.setItem("activeBrandId", brands[0].id);

    return {
      accountId: loginResult.data["id"],
      username: loginResult.data["username"],
      brands,
      activeBrandId: brands[0].id,
    };
  } catch (err) {
    const customError = {
      name: "Login Error",
      status: err.response.statusText,
      message: err?.response?.headers["x-information"] || "Unable to login", // serializable (err.response.data)
    };
    throw customError;
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loggedIn: localStorage.getItem("authToken") !== null,
    accountId: localStorage.getItem("accountId"),
    username: localStorage.getItem("username"),
    brands: localStorage.getItem("brands") ? JSON.parse(localStorage.getItem("brands")) : null,
    activeBrandId: localStorage.getItem("activeBrandId") ? parseInt(localStorage.getItem("activeBrandId")) : null,
    loading: false,
    error: null,
  },
  // reducers actions
  reducers: {
    // create action
    // loggedIn: (state, action) => ({
    //         ...state,
    //     loggedIn: true,
    //     accountId: action.payload.accountId
    //     }),
    logout: {
      reducer(state, { payload }) {
        state.loggedIn = false;
        state.accountId = null;
        state.brands = null;
        state.activeBrandId = null;
      },
      prepare() {
        // Clear the local storage account details (token and accountId)
        localStorage.removeItem("authToken");
        localStorage.removeItem("accountId");
        localStorage.removeItem("username");
        localStorage.removeItem("brands");
        localStorage.removeItem("activeBrandId");
        return {
          payload: {},
        };
      },
    },
    setActiveBrand: {
      reducer(state, { payload }) {
        state.activeBrandId = payload.brandId;
      },
      prepare(brandId) {
        localStorage.setItem("activeBrandId", brandId);
        return {
          payload: { brandId },
        };
      },
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [login.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.loggedIn = false;
      state.error = action.error.message;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      state.loggedIn = true;
      state.accountId = action.payload.accountId;
      state.username = action.payload.username;
      state.brands = action.payload.brands;
      state.activeBrandId = action.payload.activeBrandId;
    },
  },
});
const { actions, reducer } = authSlice;
export const { logout, setActiveBrand } = actions;
export default reducer;
