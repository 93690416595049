import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";

import { setSnackbarErrorMessage } from "./snackbarSlice";

export const loadCurrencies = createAsyncThunk("currencies/loadCurrencies", async (data, thunkAPI) => {
  try {
    const result = await createAxiosInstance(thunkAPI.dispatch).get(`/cms/currencies`);

    return { currencies: result.data };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain currency details";
    const customError = {
      name: "Currency List Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

const currencySlice = createSlice({
  name: "currency",
  initialState: {
    currencies: null,
    loading: false,
    error: null,
  },
  // reducers actions
  reducers: {},
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [loadCurrencies.pending]: (state) => {
      state.loading = true;
      state.currencies = null;
      state.error = null;
    },
    [loadCurrencies.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [loadCurrencies.fulfilled]: (state, action) => {
      state.loading = false;
      state.currencies = action.payload.currencies;
    },
  },
});
const { actions, reducer } = currencySlice;
// export const { logout, setActiveBrand } = actions
export default reducer;
