const AssetPermissions = {
  VIEW_ALL_ASSETS: 1687, // If permission is assigned, operator should be able to view all Assets.
  EDIT_IMAGE_ASSETS: 1688, // If permission is assigned, operator should able to add, edit and save changes in Images.
  DELETE_IMAGE_ASSETS: 1689, // If permission is assigned, operator should able delete Images.
  EDIT_ICON_ASSETS: 1690, // If permission is assigned, operator should able to add, edit and save changes in Icons.
  DELETE_ICON_ASSETS: 1691, // If permission is assigned, operator should able delete Icons.
  EDIT_LOGO_ASSETS: 1692, // If permission is assigned, operator should able to add, edit and save changes in Logos.
  DELETE_LOGO_ASSETS: 1693, // If permission is assigned, operator should able delete Logos.
  EDIT_VIDEO_ASSETS: 1694, // If permission is assigned, operator should able to add, edit and save changes in Videos.
  DELETE_VIDEO_ASSETS: 1695, // If permission is assigned, operator should able delete Videos.
  EDIT_FONT_ASSETS: 1696, // If permission is assigned, operator should able to add, edit and save changes in Fonts.
  DELETE_FONT_ASSETS: 1697, // If permission is assigned, operator should able delete Fonts.
  PUBLISH_ALL_ASSETS_CHANGES: 1, // If permission is assigned, operator should be able to Publish all pending changes for My Assets.
  PUBLISH_OWN_ASSETS_CHANGES: 1, // If permission is assigned, operator should be able to Publish his own pending changes for My Assets.
};

Object.freeze(AssetPermissions);

export default AssetPermissions;
