export const getLanguageName = (lang = localStorage.getItem("language")) => {
  let name = "N/A";

  switch (lang) {
    case "en":
      name = "English";
      break;
    case "de":
      name = "Deutsch [German]";
      break;
    case "es":
      name = "Español [Spanish]";
      break;
    case "fr":
      name = "Français [French]";
      break;
    case "in":
    case "id":
      name = "Bahasa Indonesia [Indonesian]";
      break;
    case "ja":
      name = "日本語 [Japanese]";
      break;
    case "ko":
      name = "한국어 [Korean]";
      break;
    case "ms":
      name = "Bahasa Malaysia [Malay]";
      break;
    case "pt":
      name = "Português [Portuguese]";
      break;
    case "ru":
      name = "русский язык [Russian]";
      break;
    case "th":
      name = "ภาษาไทย [Thai]";
      break;
    case "vi":
      name = "Tiếng Việt [Vietnamese]";
      break;
    case "zh":
      name = "汉语 [Chinese]";
      break;
    case "km":
      name = "ខ្មែរ [Khmer]";
      break;
    case "hi":
      name = "हिन्दी [Hindi]";
      break;
    case "te":
      name = "తెలుగు [Telugu]";
      break;
    case "bn":
      name = "বাংলা [Bengali]";
      break;

    default:
      console.log("Language not found: ", lang);
      break;
  }

  return name;
};
