const WidgetPermissions = {
  VIEW_ALL_WIDGETS: 1658, // If permission is assigned, operator should be able to view all Widgets.
  EDIT_BANNER_ADS_WIDGETS: 1659, // If permission is assigned, operator should able to add, edit and save changes in Banner Ads Widget.
  DELETE_BANNER_ADS_WIDGETS: 1660, // If permission is assigned, operator should able delete Banner Ads Widget.
  EDIT_BETSLIP_WIDGETS: 1661, // If permission is assigned, operator should able to add, edit and save changes in Betslip Widget.
  DELETE_BETSLIP_WIDGETS: 1662, // If permission is assigned, operator should able delete Betslip Widget.
  EDIT_CAROUSEL_BANNER_WIDGETS: 1663, // If permission is assigned, operator should able to add, edit and save changes in Carousel Banner Widget.
  DELETE_CAROUSEL_BANNER_WIDGETS: 1664, // If permission is assigned, operator should able to delete Carousel Banner Widget.
  EDIT_FAVOURITE_WIDGETS: 1665, // If permission is assigned, operator should able to add, edit and save changes in Favourites Widget.
  DELETE_FAVOURITE_WIDGETS: 1666, // If permission is assigned, operator should able to delete Favourites Widget.
  EDIT_FEATURED_LEAGUES_WIDGETS: 1698, // If permission is assigned, operator should able to add, edit and save changes in Featured Leagues Widget.
  DELETE_FEATURED_LEAGUES_WIDGETS: 1699, // If permission is assigned, operator should able to delete Featured Leagues Widget.
  EDIT_FEATURED_EVENTS_WIDGETS: 1667, // If permission is assigned, operator should able to add, edit and save changes in Favourites Widget.
  DELETE_FEATURED_EVENTS_WIDGETS: 1668, // If permission is assigned, operator should able to delete Featured Events Widget.
  EDIT_IFRAME_BLOCK_WIDGETS: 1669, // If permission is assigned, operator should able to add, edit and save changes in iFrame Blocks Widget.
  DELETE_IFRAME_BLOCK_WIDGETS: 1670, // If permission is assigned, operator should able to delete iFrame Blocks Widget.
  EDIT_MATCH_TRACKER_WIDGETS: 1671, // If permission is assigned, operator should able to add, edit and save changes in Match Tracker Widget.
  DELETE_MATCH_TRACKER_WIDGETS: 1672, // If permission is assigned, operator should able to delete Match Tracker Widget.
  EDIT_ANNOUNCEMENTS_WIDGETS: -333, // If permission is assigned, operator should able to add, edit and save changes in Announcements Widget.
  DELETE_ANNOUNCEMENTS_WIDGETS: -333, // If permission is assigned, operator should able to delete Announcements Widget.
  EDIT_SPORTS_CAROUSEL_WIDGETS: 1677, // If permission is assigned, operator should able to add, edit and save changes in Sports Carousel Widget.
  DELETE_SPORTS_CAROUSEL_WIDGETS: 1678, // If permission is assigned, operator should able to delete Sports Carousel Widget.
  EDIT_SPORTS_TREE_WIDGETS: 1679, // If permission is assigned, operator should able to add, edit and save changes in Sports Tree Widget.
  DELETE_SPORTS_TREE_WIDGETS: 1680, // If permission is assigned, operator should able to delete Sports Tree Widget.
  EDIT_VIDEO_STREAMING_WIDGETS: 1681, // If permission is assigned, operator should able to add, edit and save changes in Video Streaming Widget.
  DELETE_VIDEO_STREAMING_WIDGETS: 1682, // If permission is assigned, operator should able to delete Video Streaming Widget.
  EDIT_CONTENT_PAGE_WIDGETS: 1683, // If permission is assigned, operator should able to add, edit and save changes in Content Pages Widget.
  DELETE_CONTENT_PAGE_WIDGETS: 1684, // If permission is assigned, operator should able to delete Content Pages Widget.
  EDIT_MENU_WIDGETS: 1685, // If permission is assigned, operator should able to add, edit and save changes in Menus Widget.
  DELETE_MENU_WIDGETS: 1686, // If permission is assigned, operator should able to delete Menus Widget.
  EDIT_MATCH_RECOMMENDATION_WIDGETS: 1715, // If permission is assigned, operator should able to add, edit and save changes in Match Recommendation Widget.
  DELETE_MATCH_RECOMMENDATION_WIDGETS: 1716, // If permission is assigned, operator should able to delete Match Recommendation Widget.
  EDIT_LEAGUE_RECOMMENDATION_WIDGETS: 1717, // If permission is assigned, operator should able to add, edit and save changes in League Recommendation Widget.
  DELETE_LEAGUE_RECOMMENDATION_WIDGETS: 1718, // If permission is assigned, operator should able to delete League Recommendation Widget.
  PUBLISH_ALL_WIDGET_CHANGES: 1641, // If permission is assigned, operator should be able to Publish all pending changes for My Widget.
  PUBLISH_OWN_WIDGET_CHANGES: 1640, // If permission is assigned, operator should only be able to Publish Own changes for My Widget
  REVERT_WIDGET_CHANGES: 1636, // If permission is assigned, operator should be able to revert back Widget changes.
};

Object.freeze(WidgetPermissions);

export default WidgetPermissions;
