import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";

import { setSnackbarErrorMessage } from "./snackbarSlice";

export const loadPermissions = createAsyncThunk("permissions/loadPermissions", async (data, thunkAPI) => {
  try {
    const result = await createAxiosInstance(thunkAPI.dispatch).get(
      `/cms/accounts/${localStorage.getItem("accountId")}/permissions`,
    );

    return { permissions: result.data };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain account permissions";
    const customError = {
      name: "Permissions Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

const permissionSlice = createSlice({
  name: "permissions",
  initialState: {
    permissions: null,
    loading: false,
    error: null,
  },
  // reducers actions
  reducers: {},
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [loadPermissions.pending]: (state) => {
      state.loading = true;
      state.permissions = null;
      state.error = null;
    },
    [loadPermissions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [loadPermissions.fulfilled]: (state, action) => {
      state.loading = false;
      state.permissions = action.payload.permissions;
    },
  },
});
const { actions, reducer } = permissionSlice;
// export const { logout, setActiveBrand } = actions
export default reducer;
