import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { spacing } from "@material-ui/system";
import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

import Loader from "../../../components/Loader";
import { login } from "../../../redux/slices/authSlice";

const Spacer = styled.div(spacing);

const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

class SignIn extends Component {
  //
  // const BigAvatar = styled(Avatar)`
  //   width: 92px;
  //   height: 92px;
  //   text-align: center;
  //   margin: 0 auto ${props => props.theme.spacing(5)}px;
  // `;

  state = {
    username: localStorage.getItem("rememberMeUsername") || "",
    usernameEntered: localStorage.getItem("rememberMeUsername") !== null,
    password: "",
    passwordEntered: false,
    submitEnabled: false,
    rememberMe: localStorage.getItem("rememberMeUsername") !== null,
    progressCompleted: 50,
  };

  usernameChangeHandler = (e) => {
    const username = e.target.value;
    const usernameEntered = !(username.length === 0 || !username.trim());

    this.setState({
      ...this.state,
      username,
      usernameEntered,
      submitEnabled: usernameEntered && this.state.passwordEntered,
    });
  };

  passwordChangeHandler = (e) => {
    const password = e.target.value;
    const passwordEntered = !(password.length === 0 || !password.trim());

    this.setState({
      ...this.state,
      password,
      passwordEntered,
      submitEnabled: passwordEntered && this.state.usernameEntered,
    });
  };

  rememberMeHandler = (e) => {
    const rememberMe = e.target.checked;

    this.setState({
      ...this.state,
      rememberMe,
    });
  };

  loginHandler = (e) => {
    e.preventDefault();

    if (this.state.rememberMe) {
      localStorage.setItem("rememberMeUsername", this.state.username);
    }

    this.props.login({ username: this.state.username, password: this.state.password });
  };

  render() {
    return (
      <Wrapper>
        <Helmet title="Sign In" />

        {this.props.loggedIn ? <Redirect to="/" /> : null}

        {this.props.error ? (
          <Alert mb={4} severity="error">
            {this.props.error}
          </Alert>
        ) : null}

        <Spacer mb={4} />

        <Typography gutterBottom align="center" component="h1" variant="h4">
          CMS
        </Typography>
        <Typography align="center" component="h2" variant="body1">
          {this.props.loading ? "Signing In, Please Wait..." : "Sign in to your account to continue"}
        </Typography>

        {this.props.loading ? (
          <Loader />
        ) : (
          <form>
            <FormControl fullWidth required margin="normal">
              <InputLabel htmlFor="username">Username</InputLabel>
              <Input
                autoFocus
                autoComplete="username"
                id="username"
                name="username"
                value={this.state.username}
                onChange={this.usernameChangeHandler}
              />
            </FormControl>
            <FormControl fullWidth required margin="normal">
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                autoComplete="current-password"
                id="password"
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.passwordChangeHandler}
              />
            </FormControl>
            <FormControlLabel
              control={
                this.state.rememberMe ? (
                  <Checkbox checked color="primary" value="remember" onChange={this.rememberMeHandler} />
                ) : (
                  <Checkbox color="primary" value="remember" onChange={this.rememberMeHandler} />
                )
              }
              label="Remember me"
            />

            {this.state.submitEnabled ? (
              <Button fullWidth color="primary" mb={2} variant="contained" onClick={this.loginHandler}>
                Sign in
              </Button>
            ) : (
              <Button disabled fullWidth color="primary" mb={2} variant="contained">
                Sign in
              </Button>
            )}
          </form>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
    loading: state.auth.loading,
    error: state.auth.error,
    loggedIn: state.auth.loggedIn,
});

export default connect(mapStateToProps, {login})(SignIn);
