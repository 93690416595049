import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import originalAxios from "axios";

import createAxiosInstance from "../async/axios";

import { setSnackbarErrorMessage } from "./snackbarSlice";
import { loadWidgetsByAsset } from "./widgetSlice";

export const loadLayouts = createAsyncThunk("layout/loadAll", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    // const result = await createAxiosInstance(thunkAPI.dispatch).get(`/cms/brands/${activeBrandId}/siteconfigs/${siteConfigType}`);
    const result = await createAxiosInstance(thunkAPI.dispatch).get(`/cms/brands/${activeBrandId}/layouts`);

    return { layouts: result.data };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain layout details";
    const customError = {
      name: "Layout List Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const createLayout = createAsyncThunk("layout/createLayout", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const bodyData = { ...data };

    // const result = await createAxiosInstance(thunkAPI.dispatch).put(`/cms/brands/${activeBrandId}/siteconfigs/${siteConfigType}`, bodyData.siteConfig);
    const result = await createAxiosInstance(thunkAPI.dispatch).post(`/cms/brands/${activeBrandId}/layouts`, bodyData);

    thunkAPI.dispatch(loadLayouts());

    return {};
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to save layout";
    const customError = {
      name: "Layout Save Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const updateLayout = createAsyncThunk("layout/updateLayout", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const bodyData = { ...data };

    // const result = await createAxiosInstance(thunkAPI.dispatch).put(`/cms/brands/${activeBrandId}/siteconfigs/${siteConfigType}`, bodyData.siteConfig);
    const result = await createAxiosInstance(thunkAPI.dispatch).put(
      `/cms/brands/${activeBrandId}/layouts/${data.id}`,
      bodyData,
    );

    thunkAPI.dispatch(loadLayouts());

    return {};
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to save layout";
    const customError = {
      name: "Layout Save Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

let loadLayoutWidgetsCancelToken = null;

export const loadLayoutWidgets = createAsyncThunk("layout/loadWidgetsByLayoutTemplate", async (data, thunkAPI) => {
  try {
    let thisCancelToken = null;
    // Check if there are any previous pending requests
    if (loadLayoutWidgetsCancelToken) {
      // cancel the previous operation...
      loadLayoutWidgetsCancelToken.cancel("Operation canceled due to new request.");
    }
    // Save the cancel token for the current request
    loadLayoutWidgetsCancelToken = originalAxios.CancelToken.source();
    thisCancelToken = loadLayoutWidgetsCancelToken;

    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    // const result = await createAxiosInstance(thunkAPI.dispatch).get(`/cms/brands/${activeBrandId}/siteconfigs/${siteConfigType}`);
    const result = await createAxiosInstance(thunkAPI.dispatch).get(
      `/cms/brands/${activeBrandId}/layouts/${data["layoutId"]}/widgets`,
      {
        cancelToken: thisCancelToken.token,
      },
    );

    return { layoutWidgets: result.data };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain layout widget details";
    const customError = {
      name: "Layout Widget List Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const saveLayoutWidgets = createAsyncThunk("layout/saveLayoutAndWidgets", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const layout = { ...data.layout };
    let layoutId = layout["id"];
    delete layout["id"];
    delete layout["modifiedBy"];
    delete layout["dirty"];

    const widgets = data.widgets;

    // save the layout template...
    let result = null;
    if (layoutId) {
      if (layout.layoutTemplate !== thunkAPI.getState().layout.layouts.find((l) => l.id === layoutId).layoutTemplate) {
        result = await createAxiosInstance(thunkAPI.dispatch).put(
          `/cms/brands/${activeBrandId}/layouts/${layoutId}`,
          layout,
        );
      }
    } else {
      result = await createAxiosInstance(thunkAPI.dispatch).post(`/cms/brands/${activeBrandId}/layouts`, layout);
      layoutId = result.data.id;
    }

    // ...and then save the layout widgets... (make sure not to send inherited stuff)
    const widgetBody = widgets
      .filter((widget) => !widget.inherited)
      .map((widget, index) => ({
        section: widget.section,
        settings: widget.settings,
        ordinal: index,
        widgetId: widget.id,
      }));
    result = await createAxiosInstance(thunkAPI.dispatch).put(
      `/cms/brands/${activeBrandId}/layouts/${layoutId}/widgets`,
      widgetBody,
    );

    // reload data cleanly
    thunkAPI.dispatch(loadLayouts());
    thunkAPI.dispatch(loadLayoutWidgets({ layoutId }));

    return {};
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to save layout";
    const customError = {
      name: "Layout Save Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const deleteLayoutWidgets = createAsyncThunk("layout/deleteLayoutAndWidgets", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const layoutId = data.layoutId;

    // delete the layout widgets
    const result = await createAxiosInstance(thunkAPI.dispatch).delete(
      `/cms/brands/${activeBrandId}/layouts/${layoutId}`,
    );

    // reload data cleanly
    thunkAPI.dispatch(loadLayouts());
    thunkAPI.dispatch(loadLayoutWidgets({ layoutId }));

    return {};
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to save layout";
    const customError = {
      name: "Layout Save Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

let loadAvailableInheritanceLayoutsCancelToken = null;

export const loadValidLayoutsForInheritance = createAsyncThunk(
  "layout/loadValidLayoutsForInheritance",
  async (data, thunkAPI) => {
    try {
      let thisCancelToken = null;
      // Check if there are any previous pending requests
      if (loadAvailableInheritanceLayoutsCancelToken) {
        // cancel the previous operation...
        loadAvailableInheritanceLayoutsCancelToken.cancel("Operation canceled due to new request.");
      }
      // Save the cancel token for the current request
      loadAvailableInheritanceLayoutsCancelToken = originalAxios.CancelToken.source();
      thisCancelToken = loadAvailableInheritanceLayoutsCancelToken;

      const activeBrandId = thunkAPI.getState().auth.activeBrandId;

      const playerView = data["playerView"];

      // const result = await createAxiosInstance(thunkAPI.dispatch).get(`/cms/brands/${activeBrandId}/siteconfigs/${siteConfigType}`);
      const result = await createAxiosInstance(thunkAPI.dispatch).get(
        `/cms/brands/${activeBrandId}/layouts/availableLayoutSectionsForInheritance?playerView=${playerView}`,
        {
          cancelToken: thisCancelToken.token,
        },
      );

      return { availableLayoutsForInheritancePerSection: result.data };
    } catch (err) {
      const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain Inheritance Section details";
      const customError = {
        name: "Layout Inheritance Section List Fetch Error",
        status: err.response.statusText,
        message: errorMessage, // serializable (err.response.data)
      };
      thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
      throw customError;
    }
  },
);

export const saveLayoutInheritance = createAsyncThunk("layout/saveLayoutInheritance", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const childLayout = { ...data.childLayout };
    let layoutId = childLayout["id"];
    delete childLayout["id"];

    // save the layout template...
    let result = null;
    if (layoutId) {
      if (
        childLayout.layoutTemplate !== thunkAPI.getState().layout.layouts.find((l) => l.id === layoutId).layoutTemplate
      ) {
        result = await createAxiosInstance(thunkAPI.dispatch).put(
          `/cms/brands/${activeBrandId}/layouts/${layoutId}`,
          childLayout,
        );
      }
    } else {
      result = await createAxiosInstance(thunkAPI.dispatch).post(`/cms/brands/${activeBrandId}/layouts`, childLayout);
      layoutId = result.data.id;
    }

    // ...and then save the inheritance rules...
    const inheritanceRequest = {
      childLayoutId: layoutId,
      parentLayoutId: data.parentLayout.id,
      section: data.section,
    };
    result = await createAxiosInstance(thunkAPI.dispatch).post(
      `/cms/brands/${activeBrandId}/layouts/inheritance`,
      inheritanceRequest,
    );

    // reload data cleanly
    thunkAPI.dispatch(loadLayouts());
    thunkAPI.dispatch(loadLayoutWidgets({ layoutId }));

    return {};
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to save layout inheritance";
    const customError = {
      name: "Layout Inheritance Save Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const deleteLayoutInheritance = createAsyncThunk("layout/deleteLayoutInheritance", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const childLayout = { ...data.childLayout };
    let layoutId = childLayout["id"];
    delete childLayout["id"];

    // save the layout template...
    let result = null;
    if (layoutId) {
      if (
        childLayout.layoutTemplate !== thunkAPI.getState().layout.layouts.find((l) => l.id === layoutId).layoutTemplate
      ) {
        result = await createAxiosInstance(thunkAPI.dispatch).put(
          `/cms/brands/${activeBrandId}/layouts/${layoutId}`,
          childLayout,
        );
      }
    } else {
      result = await createAxiosInstance(thunkAPI.dispatch).post(`/cms/brands/${activeBrandId}/layouts`, childLayout);
      layoutId = result.data.id;
    }

    // ...and then delete the inheritance rules...
    const clone = data["clone"];
    const section = data["section"];
    result = await createAxiosInstance(thunkAPI.dispatch).delete(
      `/cms/brands/${activeBrandId}/layouts/inheritance?layoutId=${layoutId}&section=${section}&clone=${clone}`,
    );

    // reload data cleanly
    thunkAPI.dispatch(loadLayouts());
    thunkAPI.dispatch(loadLayoutWidgets({ layoutId }));

    return {};
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to delete layout inheritance";
    const customError = {
      name: "Layout Inheritance Delete Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const loadLayoutsByWidget = createAsyncThunk("widgets/loadLayoutsUsingWidget", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;
    const widgetId = data["widgetId"];

    const result = await createAxiosInstance(thunkAPI.dispatch).get(
      `/cms/brands/${activeBrandId}/layouts/widgetsearch/${widgetId}`,
    );

    return { widgetId, layouts: result.data };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain layout by widget";
    const customError = {
      name: "Layout List Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    layouts: null,
    layoutsByWidget: {},
    loading: false,
    creating: false,
    updating: false,
    layoutWidgets: null,
    loadingWidgets: false,
    savingWidgets: false,
    deletingWidgets: false,
    error: null,
    availableLayoutsForInheritancePerSection: {},
  },
  // reducers actions
  reducers: {
    clearLayoutWidgets: {
      reducer(state, { payload }) {
        state.layoutWidgets = null;
      },
      prepare() {
        // Check if there are any previous pending requests
        if (loadLayoutWidgetsCancelToken) {
          // cancel the previous operation...
          loadLayoutWidgetsCancelToken.cancel("Operation canceled due to request to clear out.");
        }
        return {
          payload: {},
        };
      },
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [loadLayouts.pending]: (state) => {
      state.loading = true;
      state.layouts = null;
      state.error = null;
    },
    [loadLayouts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [loadLayouts.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.layouts = action.payload.layouts;
    },
    [createLayout.pending]: (state) => {
      state.creating = true;
      state.error = null;
    },
    [createLayout.rejected]: (state, action) => {
      state.creating = false;
      state.error = action.error.message;
    },
    [createLayout.fulfilled]: (state, action) => {
      state.creating = false;
      state.error = null;
    },
    [updateLayout.pending]: (state) => {
      state.updating = true;
      state.error = null;
    },
    [updateLayout.rejected]: (state, action) => {
      state.updating = false;
      state.error = action.error.message;
    },
    [updateLayout.fulfilled]: (state, action) => {
      state.updating = false;
      state.error = null;
    },
    [loadLayoutWidgets.pending]: (state) => {
      state.loadingWidgets = true;
      state.layoutWidgets = null;
      state.error = null;
    },
    [loadLayoutWidgets.rejected]: (state, action) => {
      state.loadingWidgets = false;
      state.error = action.error.message;
    },
    [loadLayoutWidgets.fulfilled]: (state, action) => {
      state.loadingWidgets = false;
      state.error = null;
      state.layoutWidgets = action.payload.layoutWidgets;
    },
    [saveLayoutWidgets.pending]: (state) => {
      state.savingWidgets = true;
      state.error = null;
    },
    [saveLayoutWidgets.rejected]: (state, action) => {
      state.savingWidgets = false;
      state.error = action.error.message;
    },
    [saveLayoutWidgets.fulfilled]: (state, action) => {
      state.savingWidgets = false;
      state.error = null;
    },
    [deleteLayoutWidgets.pending]: (state) => {
      state.deletingWidgets = true;
      state.error = null;
    },
    [deleteLayoutWidgets.rejected]: (state, action) => {
      state.deletingWidgets = false;
      state.error = action.error.message;
    },
    [deleteLayoutWidgets.fulfilled]: (state, action) => {
      state.deletingWidgets = false;
      state.error = null;
    },
    [loadValidLayoutsForInheritance.pending]: (state) => {
      state.loading = true;
      state.availableLayoutsForInheritancePerSection = {};
      state.error = null;
    },
    [loadValidLayoutsForInheritance.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [loadValidLayoutsForInheritance.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.availableLayoutsForInheritancePerSection = action.payload.availableLayoutsForInheritancePerSection;
    },
    [saveLayoutInheritance.pending]: (state) => {
      state.creating = true;
      state.error = null;
    },
    [saveLayoutInheritance.rejected]: (state, action) => {
      state.creating = false;
      state.error = action.error.message;
    },
    [saveLayoutInheritance.fulfilled]: (state, action) => {
      state.creating = false;
      state.error = null;
    },
    [deleteLayoutInheritance.pending]: (state) => {
      state.creating = true;
      state.error = null;
    },
    [deleteLayoutInheritance.rejected]: (state, action) => {
      state.creating = false;
      state.error = action.error.message;
    },
    [deleteLayoutInheritance.fulfilled]: (state, action) => {
      state.creating = false;
      state.error = null;
    },
    [loadLayoutsByWidget.pending]: (state, action) => {
      const widgetId = action.meta.arg.widgetId;
      delete state.layoutsByWidget[widgetId];
    },
    [loadLayoutsByWidget.rejected]: (state, action) => {
      //
    },
    [loadLayoutsByWidget.fulfilled]: (state, action) => {
      state.layoutsByWidget[action.payload.widgetId] = action.payload.layouts;
    },
  },
});

const { actions, reducer } = layoutSlice;
export const { clearLayoutWidgets } = actions;
export default reducer;
