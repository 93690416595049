import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";

export const getInitialState = (sports = null) => ({
  error: null,
  loading: false,
  sports,
});

export const getPeriodsBySportCode = createAsyncThunk("periods/loadAll", async ({ sportCode }, thunkAPI) => {
  try {
    const axios = createAxiosInstance(thunkAPI.dispatch);

    const result = await axios.get(`/cms/periods?code=${sportCode}`);

    return {
      periods: result.data,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to obtain the period list", // serializable (err.response.data)
      name: "Period List Fetch Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

const periodSlice = createSlice({
  extraReducers: {
    [getPeriodsBySportCode.pending]: (state) => {
      state.error = null;
      state.loading = true;
      state.periods = [];
    },
    [getPeriodsBySportCode.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getPeriodsBySportCode.fulfilled]: (state, action) => {
      state.error = null;
      state.loading = false;
      state.periods = action.payload.periods;
    },
  },
  initialState: getInitialState(),
  name: "period",
  reducers: {},
});

const { reducer } = periodSlice;
export default reducer;
