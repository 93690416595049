import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";

import { setSnackbarErrorMessage } from "./snackbarSlice";
import { loadWidgetsByAsset } from "./widgetSlice";

export const loadSiteConfigsByType = createAsyncThunk("siteConfigs/loadSiteConfigsByType", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;
    const siteConfigType = data["siteConfigType"];

    const result = await createAxiosInstance(thunkAPI.dispatch).get(
      `/cms/brands/${activeBrandId}/siteconfigs/${siteConfigType}`,
    );

    return { siteConfig: result.data.cmsSiteConfigData ? result.data.cmsSiteConfigData : {} };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain site config details";
    const customError = {
      name: "Site Config List Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const saveSiteConfig = createAsyncThunk("siteConfigs/saveSiteConfig", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;

    const siteConfigType = data["siteConfigType"];

    const bodyData = { ...data };
    delete bodyData["siteConfigType"];

    const result = await createAxiosInstance(thunkAPI.dispatch).put(
      `/cms/brands/${activeBrandId}/siteconfigs/${siteConfigType}`,
      bodyData.siteConfig,
    );

    thunkAPI.dispatch(loadSiteConfigsByType({ siteConfigType }));

    return {};
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to save site config";
    const customError = {
      name: "Site Config Save Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

export const loadSiteConfigsByAsset = createAsyncThunk("widgets/loadSiteConfigByAsset", async (data, thunkAPI) => {
  try {
    const activeBrandId = thunkAPI.getState().auth.activeBrandId;
    const assetId = data["assetId"];

    const result = await createAxiosInstance(thunkAPI.dispatch).get(
      `/cms/brands/${activeBrandId}/siteconfigs/assetsearch/${assetId}`,
    );

    return { assetId, siteConfigs: result.data };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain site config by asset";
    const customError = {
      name: "Site Config List Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

const siteConfigSlice = createSlice({
  name: "siteConfig",
  initialState: {
    siteConfig: null,
    siteConfigByAsset: {},
    loading: false,
    saving: false,
    error: null,
  },
  // reducers actions
  reducers: {
    clearActiveSiteConfig: {
      reducer(state, { payload }) {
        state.siteConfig = null;
      },
      prepare() {
        return {
          payload: {},
        };
      },
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [loadSiteConfigsByType.pending]: (state) => {
      state.loading = true;
      state.siteConfig = null;
      state.error = null;
    },
    [loadSiteConfigsByType.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [loadSiteConfigsByType.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.siteConfig = action.payload.siteConfig;
    },
    [saveSiteConfig.pending]: (state) => {
      state.saving = true;
      state.error = null;
    },
    [saveSiteConfig.rejected]: (state, action) => {
      state.saving = false;
      state.error = action.error.message;
    },
    [saveSiteConfig.fulfilled]: (state, action) => {
      state.saving = false;
      state.error = null;
    },
    [loadSiteConfigsByAsset.pending]: (state, action) => {
      const assetId = action.meta.arg.assetId;
      delete state.siteConfigByAsset[assetId];
    },
    [loadSiteConfigsByAsset.rejected]: (state, action) => {
      //
    },
    [loadSiteConfigsByAsset.fulfilled]: (state, action) => {
      state.siteConfigByAsset[action.payload.assetId] = action.payload.siteConfigs;
    },
  },
});

const { actions, reducer } = siteConfigSlice;
export const { clearActiveSiteConfig } = actions;
export default reducer;
