const LayoutPermissions = {
  VIEW_LAYOUTS: 1632, // If permission is assigned, operator should be able to view Layouts.
  EDIT_LAYOUTS: 1633, // If permission is assigned, operator should able to add, edit and save changes in Layouts.
  PUBLISH_ALL_LAYOUT_CHANGES: 1643, // If permission is assigned, operator should be able to Publish all pending changes for Layouts.
  PUBLISH_OWN_LAYOUT_CHANGES: 1642, // If permission is assigned, operator should only be able to Publish Own changes for Layouts.
  REVERT_LAYOUT_CHANGES: 1637, // If permission is assigned, operator should be able to revert back Layout changes.
};

Object.freeze(LayoutPermissions);

export default LayoutPermissions;
