import { createMuiTheme } from "@material-ui/core/styles";

import breakpoints from "./breakpoints";
import overrides from "./overrides";
import props from "./props";
import shadows from "./shadows";
import typography from "./typography";
import variants from "./variants";

const theme = (variant) =>
  createMuiTheme(
    {
      spacing: 4,
      breakpoints,
      overrides,
      props,
      typography,
      shadows,
      body: variant.body,
      header: variant.header,
      palette: variant.palette,
      sidebar: variant.sidebar,
    },
    variant.name,
  );

const themes = variants.map((variant) => theme(variant));

export default themes;
