const SiteConfigurationPermissions = {
  VIEW_ALL_SITE_CONFIGS: 1644, // If permission is assigned, operator should be able to view all site configuration.
  EDIT_BRAND_SETTINGS_CONFIG: 1645, // If permission is assigned, operator should be able to edit and save changes in Brand Setting.
  EDIT_BRAND_LOGO_AND_FAVICON_CONFIG: 1646, // If permission is assigned, operator should be able to edit and save changes in Brand Logo and Favicon.
  EDIT_ACCOUNT_REGISTRATION_CONFIG: 1647, // If permission is assigned, operator should be able to edit and save changes in Account Registration.
  EDIT_APPEARANCE_CONFIG: 1648, // If permission is assigned, operator should be able to edit and save changes in Appearance.
  EDIT_BETTING_CONFIG: 1649, // If permission is assigned, operator should be able to edit and save changes in Betting Configuration.
  EDIT_SPORTSBOOK_CONTENT_CONFIG: 1650, // If permission is assigned, operator should be able to edit and save changes in Sports Book Content.
  EDIT_SPORTS_RESULTS_CONFIG: 1651, // If permission is assigned, operator should be able to edit and save changes in Sports Results.
  EDIT_AFFILIATES_CONFIG: 1652, // If permission is assigned, operator should be able to edit and save changes in Affiliates
  EDIT_BETRADAR_VIRTUAL_CONFIG: 1653, // If permission is assigned, operator should be able to edit and save changes in Betradar Virtual.
  EDIT_KIRON_VIRTUAL_CONFIG: 1654, // If permission is assigned, operator should be able to edit and save changes in Kiron Virtual.
  EDIT_MANUAL_PAYMENTS_CONFIG: 1655, // If permission is assigned, operator should be able to edit and save changes in Manual Payments.
  EDIT_NETELLER_PAYMENTS_CONFIG: 1656, // If permission is assigned, operator should be able to edit and save changes in Neteller Payments.
  EDIT_SKRILL_PAYMENTS_CONFIG: 1657, // If permission is assigned, operator should be able to edit and save changes in Skrill Payments.
  EDIT_MARKET_TYPE_PREFERENCES_CONFIG: 1719, // If permission is assigned, operator should be able to edit and save changes in Market Type Preferences.
  EDIT_CASINO_MICROGAMING_CONFIG: 1617,
  EDIT_CASINO_SOLID_GAMING_CONFIG: 1619,
  PUBLISH_ALL_SITE_CONFIG_CHANGES: 1639, // If permission is assigned, operator should be able to Publish all pending changes for Site Configuration.
  PUBLISH_OWN_SITE_CONFIG_CHANGES: 1638, // If permission is assigned, operator should only be able to Publish Own changes for Site Configuration.
  REVERT_SITE_CONFIG_CHANGES: 1635, // If permission is assigned, operator should be able to revert back Site Configuration changes.
};

Object.freeze(SiteConfigurationPermissions);

export default SiteConfigurationPermissions;
