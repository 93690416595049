import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";

import { setSnackbarErrorMessage } from "./snackbarSlice";

export const loadCountries = createAsyncThunk("country/loadCountries", async (data, thunkAPI) => {
  try {
    const result = await createAxiosInstance(thunkAPI.dispatch).get(`/cms/countries`);

    return { countries: result.data };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain country details";
    const customError = {
      name: "Country list Fetch Error",
      status: err.response.statusText,
      message: errorMessage, // serializable (err.response.data)
    };
    thunkAPI.dispatch(setSnackbarErrorMessage(errorMessage));
    throw customError;
  }
});

const countrySlice = createSlice({
  name: "country",
  initialState: {
    countries: null,
    loading: false,
    error: null,
  },
  // reducers actions
  reducers: {},
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [loadCountries.pending]: (state) => {
      state.loading = true;
      state.countries = null;
      state.error = null;
    },
    [loadCountries.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [loadCountries.fulfilled]: (state, action) => {
      state.loading = false;
      state.countries = action.payload.countries;
    },
  },
});
const { actions, reducer } = countrySlice;
// export const { logout, setActiveBrand } = actions
export default reducer;
