import { ColorLens, Casino, TableChart, Web, Score } from "@material-ui/icons";
import React from "react";
import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  Tag,
  Image,
  FilePlus,
  List,
  Map,
  Monitor,
  ShoppingCart,
  PieChart,
  Settings as SettingsIcon,
  Sliders,
  User,
  Users,
  Link2,
  UserPlus,
  PlayCircle,
} from "react-feather";

import async from "../components/Async";
import Empty from "../pages/pages/cms/Empty";
import AssetPermissions from "../pages/pages/cms/permissions/utils/assetPermissions";
import LayoutPermissions from "../pages/pages/cms/permissions/utils/layoutPermissions";
import SiteConfigurationPermissions from "../pages/pages/cms/permissions/utils/siteConfigurationPermissions";
import WidgetPermissions from "../pages/pages/cms/permissions/utils/widgetPermissions";

// Auth components
const SignIn = async(() => import("../pages/auth/cms/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Components components
const Alerts = async(() => import("../pages/components/Alerts"));
const Avatars = async(() => import("../pages/components/Avatars"));
const Badges = async(() => import("../pages/components/Badges"));
const Buttons = async(() => import("../pages/components/Buttons"));
const Cards = async(() => import("../pages/components/Cards"));
const Chips = async(() => import("../pages/components/Chips"));
const Dialogs = async(() => import("../pages/components/Dialogs"));
const ExpPanels = async(() => import("../pages/components/ExpansionPanels"));
const Lists = async(() => import("../pages/components/Lists"));
const Menus = async(() => import("../pages/components/Menus"));
const Pagination = async(() => import("../pages/components/Pagination"));
const Progress = async(() => import("../pages/components/Progress"));
const Snackbars = async(() => import("../pages/components/Snackbars"));
const Tooltips = async(() => import("../pages/components/Tooltips"));

// Forms components
const Pickers = async(() => import("../pages/forms/Pickers"));
const SelectionCtrls = async(() => import("../pages/forms/SelectionControls"));
const Selects = async(() => import("../pages/forms/Selects"));
const TextFields = async(() => import("../pages/forms/TextFields"));
const Dropzone = async(() => import("../pages/forms/Dropzone"));
const Editors = async(() => import("../pages/forms/Editors"));

// Icons components
const MaterialIcons = async(() => import("../pages/icons/MaterialIcons"));
const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));

// Pages components
const Blank = async(() => import("../pages/pages/Blank"));
const Settings = async(() => import("../pages/pages/Settings"));
const BrandIds = async(() => import("../pages/pages/cms/siteConfigs/BrandIds"));
const BrandSettings = async(() => import("../pages/pages/cms/siteConfigs/BrandSettings"));
const BrandMarketTypePreferences = async(() => import("../pages/pages/cms/siteConfigs/BrandMarketTypePreferences"));
const BrandLogoAndFavicon = async(() => import("../pages/pages/cms/siteConfigs/BrandLogoAndFavicon"));
const BrandAccountRegistration = async(() => import("../pages/pages/cms/siteConfigs/BrandAccountRegistration"));
const BrandBettingConfiguration = async(() => import("../pages/pages/cms/siteConfigs/BrandBettingConfiguration"));
const BrandSportsbookContent = async(() => import("../pages/pages/cms/siteConfigs/BrandSportsbookContent"));
const BrandAffiliate = async(() => import("../pages/pages/cms/siteConfigs/BrandAffiliate"));
const BrandVirtualBetRadar = async(() => import("../pages/pages/cms/siteConfigs/BrandVirtualBetRadar"));
const BrandVirtualKiron = async(() => import("../pages/pages/cms/siteConfigs/BrandVirtualKiron"));
const BrandManualPayments = async(() => import("../pages/pages/cms/siteConfigs/BrandManualPayments"));
const BrandGeneralAppearance = async(() => import("../pages/pages/cms/siteConfigs/BrandGeneralAppearance"));
const BrandResults = async(() => import("../pages/pages/cms/siteConfigs/BrandResults"));
const BetslipWidget = async(() => import("../pages/pages/cms/widgets/BetslipWidget"));
const FavouriteWidget = async(() => import("../pages/pages/cms/widgets/FavouriteWidget"));
const FeaturedLeaguesWidget = async(() => import("../pages/pages/cms/widgets/FeaturedLeaguesWidget"));
const FeaturedEventsWidget = async(() => import("../pages/pages/cms/widgets/FeaturedEventsWidget"));
const MatchTrackerWidget = async(() => import("../pages/pages/cms/widgets/MatchTrackerWidget"));
const SportsTreeWidget = async(() => import("../pages/pages/cms/widgets/SportsTreeWidget"));
const SportsCarouselWidget = async(() => import("../pages/pages/cms/widgets/SportsCarouselWidget"));
const IFrameWidget = async(() => import("../pages/pages/cms/widgets/IFrameWidget"));
const PageContentWidget = async(() => import("../pages/pages/cms/widgets/PageContentWidget"));
const MenuWidget = async(() => import("../pages/pages/cms/widgets/MenuWidget"));
const LayoutDnD = async(() => import("../pages/pages/cms/layout/LayoutWidgets"));
const BannerAdsWidget = async(() => import("../pages/pages/cms/widgets/BannerAdsWidget"));
const CentralPageBannerWidget = async(() => import("../pages/pages/cms/widgets/CentralPageBannerWidget"));
const CarouselBannerWidget = async(() => import("../pages/pages/cms/widgets/CarouselBannerWidget"));
const MatchRecommendationWidget = async(() => import("../pages/pages/cms/widgets/MatchRecommendationWidget"));
const LeagueRecommendationWidget = async(() => import("../pages/pages/cms/widgets/LeagueRecommendationWidget"));
const VideoStreamingWidget = async(() => import("../pages/pages/cms/widgets/VideoStreamingWidget"));

const Images = async(() => import("../pages/pages/cms/assets/Images"));
const Logos = async(() => import("../pages/pages/cms/assets/Logos"));
const Icons = async(() => import("../pages/pages/cms/assets/Icons"));
const Videos = async(() => import("../pages/pages/cms/assets/Videos"));
const Fonts = async(() => import("../pages/pages/cms/assets/Fonts"));

// Tables components
const SimpleTable = async(() => import("../pages/tables/SimpleTable"));
const AdvancedTable = async(() => import("../pages/tables/AdvancedTable"));

// Documentation
const Welcome = async(() => import("../pages/docs/Welcome"));
const GettingStarted = async(() => import("../pages/docs/GettingStarted"));
const EnvironmentVariables = async(() => import("../pages/docs/EnvironmentVariables"));
const Deployment = async(() => import("../pages/docs/Deployment"));
const Theming = async(() => import("../pages/docs/Theming"));
const StateManagement = async(() => import("../pages/docs/StateManagement"));
const Support = async(() => import("../pages/docs/Support"));
const Changelog = async(() => import("../pages/docs/Changelog"));
const Presentation = async(() => import("../pages/docs/Presentation"));

const homeRoutes = {
  id: "Home",
  path: "/",
  icon: <Monitor />,
  component: Empty,
  children: null,
};

const brandInfoRoutes = {
  id: "Brand Ids",
  path: "/settings/brandinfo",
  header: "Site Configuration",
  icon: <Tag />,
  component: BrandIds,
};

const brandSettingsRoutes = {
  id: "Brand Settings",
  path: "/settings/brandsettings",
  icon: <SettingsIcon />,
  component: BrandSettings,
};

const brandMarketTypeRoutes = {
  id: "Market Type Preferences",
  path: "/settings/brandmarkettypepreferences",
  icon: <List />,
  component: BrandMarketTypePreferences,
};

const brandLogoRoutes = {
  id: "Brand Logo and Favicon",
  path: "/settings/brandlogo",
  icon: <Image />,
  component: BrandLogoAndFavicon,
};

const brandAccountRoutes = {
  id: "Accounts and Registration",
  path: "/settings/accounts",
  icon: <Users />,
  component: BrandAccountRegistration,
};

const brandAppearanceRoutes = {
  id: "Appearance",
  path: "/settings/appearance",
  icon: <ColorLens />,
  component: BrandGeneralAppearance,
};

const brandBettingRoutes = {
  id: "Betting Configuration",
  path: "/settings/betting",
  icon: <Sliders />,
  component: BrandBettingConfiguration,
};

const brandSportsbookRoutes = {
  id: "Sportsbook Content",
  path: "/settings/sportsbook",
  icon: <Link2 />,
  component: BrandSportsbookContent,
};

const brandResultRoutes = {
  id: "Sport Results",
  path: "/settings/results",
  icon: <Score />,
  component: BrandResults,
};

const brandAffiliateRoutes = {
  id: "Affiliates",
  path: "/settings/affiliates",
  icon: <UserPlus />,
  component: BrandAffiliate,
};

const brandVirtualRoutes = {
  id: "Virtual Sports",
  path: "/settings/virtual",
  icon: <PlayCircle />,
  children: [
    {
      path: "/settings/virtual/betradar",
      name: "Betradar Virtual",
      component: BrandVirtualBetRadar,
    },
    {
      path: "/settings/virtual/kiron",
      name: "Kiron Virtual",
      component: BrandVirtualKiron,
    },
  ],
  component: null,
};

const brandCasinoRoutes = {
  id: "Casinos",
  path: "/settings/casino",
  icon: <Casino />,
  children: [
    {
      path: "/settings/casino/microgaming",
      name: "Microgaming",
      component: Settings,
    },
    {
      path: "/settings/casino/evolutiongaming",
      name: "Evolution Gaming",
      component: Settings,
    },
    {
      path: "/settings/casino/solidgaming",
      name: "Solid Gaming",
      component: Settings,
    },
  ],
  component: null,
};

const brandPaymentRoutes = {
  id: "Payments",
  path: "/settings/payments",
  icon: <CreditCard />,
  children: [
    {
      path: "/settings/payments/manual",
      name: "Manual Payments",
      component: BrandManualPayments,
    },
    {
      path: "/settings/payments/neteller",
      name: "Neteller Payments",
      component: Page404,
    },
    {
      path: "/settings/payments/skrill",
      name: "Skrill Payments",
      component: Page404,
    },
  ],
  component: null,
};

const widgetMyRoutes = {
  id: "My Widgets",
  path: "/widgets/mywidgets",
  header: "Widgets",
  icon: <TableChart />,
  children: [
    {
      path: "/widgets/mywidgets/bannerads",
      name: "Banner Ads",
      component: BannerAdsWidget,
    },
    {
      path: "/widgets/mywidgets/betslip",
      name: "Betslip",
      component: BetslipWidget,
    },
    {
      path: "/widgets/mywidgets/carouselbanner",
      name: "Carousel Banner",
      component: CarouselBannerWidget,
    },
    {
      path: "/widgets/mywidgets/favourites",
      name: "Favourites",
      component: FavouriteWidget,
    },
    {
      path: "/widgets/mywidgets/featuredevent",
      name: "Featured Events",
      component: FeaturedEventsWidget,
    },
    {
      path: "/widgets/mywidgets/featuredleagues",
      name: "Featured Leagues",
      component: FeaturedLeaguesWidget,
    },
    {
      path: "/widgets/mywidgets/iframeblock",
      name: "iFrame Blocks",
      component: IFrameWidget,
    },
    {
      path: "/widgets/mywidgets/matchtracker",
      name: "Match Tracker",
      component: MatchTrackerWidget,
    },
    // {  // VT - disabled on March 2021 - Understood as a subset of Menus and not providing value
    //   path: "/widgets/mywidgets/quicklinks",
    //   name: "Quick Links",
    //   component: QuickLinksWidget
    // },
    {
      path: "/widgets/mywidgets/announcements",
      name: "Announcements",
      component: Page404,
    },
    {
      path: "/widgets/mywidgets/sportsCarousel",
      name: "Sports Carousel",
      component: SportsCarouselWidget,
    },
    {
      path: "/widgets/mywidgets/sportsTree",
      name: "Sports Tree",
      component: SportsTreeWidget,
    },
    {
      path: "/widgets/mywidgets/videoStreaming",
      name: "Video Streaming",
      component: VideoStreamingWidget,
    },
    { path: "/widgets/mywidgets/menus", name: "Menus", component: MenuWidget },
    {
      path: "/widgets/mywidgets/contentPages",
      name: "Content Pages",
      component: PageContentWidget,
    },
    {
      path: "/widgets/mywidgets/matchrecommendation",
      name: "Match Recommendation",
      component: MatchRecommendationWidget,
    },
    {
      path: "/widgets/mywidgets/leaguerecommendation",
      name: "League Recommendation",
      component: LeagueRecommendationWidget,
    },
  ],
  component: null,
};

const assetRoutes = {
  id: "My Assets",
  path: "/assets",
  header: "Assets",
  icon: <FilePlus />,
  children: [
    {
      path: "/assets/images",
      name: "Images",
      component: Images,
    },
    {
      path: "/assets/icons",
      name: "Icons",
      component: Icons,
    },
    {
      path: "/assets/logos",
      name: "Logos",
      component: Logos,
    },
    {
      path: "/assets/videos",
      name: "Videos",
      component: Videos,
    },
    {
      path: "/assets/fonts",
      name: "Fonts",
      component: Fonts,
    },
  ],
};

const layoutRoutes = {
  id: "Layouts",
  path: "/layouts",
  header: "Layout",
  icon: <Web />,
  name: "Layout",
  component: LayoutDnD,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// This route is not visisble in the sidebar
const privateRoutes = {
  id: "Private",
  path: "/private",
  component: Blank,
  children: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = (permissions) =>
  [
    homeRoutes,
    brandInfoRoutes,
    brandSettingsRoutes,
    brandLogoRoutes,
    brandAccountRoutes,
    brandAppearanceRoutes,
    brandBettingRoutes,
    brandSportsbookRoutes,
    brandResultRoutes,
    brandAffiliateRoutes,
    brandVirtualRoutes,
    // brandCasinoRoutes,
    brandPaymentRoutes,
    brandMarketTypeRoutes,
    permissions?.findIndex((x) => x.id === WidgetPermissions.VIEW_ALL_WIDGETS) > -1 ? widgetMyRoutes : null,
    permissions?.findIndex((x) => x.id === AssetPermissions.VIEW_ALL_ASSETS) > -1 ? assetRoutes : null,
    layoutRoutes,
    privateRoutes,
  ].filter((x) => x);

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = (permissions) =>
  [
    homeRoutes,
    permissions?.findIndex((x) => x.id === SiteConfigurationPermissions.VIEW_ALL_SITE_CONFIGS) > -1
      ? brandInfoRoutes
      : null,
    permissions?.findIndex((x) => x.id === SiteConfigurationPermissions.VIEW_ALL_SITE_CONFIGS) > -1
      ? brandSettingsRoutes
      : null,
    permissions?.findIndex((x) => x.id === SiteConfigurationPermissions.VIEW_ALL_SITE_CONFIGS) > -1
      ? brandLogoRoutes
      : null,
    permissions?.findIndex((x) => x.id === SiteConfigurationPermissions.VIEW_ALL_SITE_CONFIGS) > -1
      ? brandAccountRoutes
      : null,
    permissions?.findIndex((x) => x.id === SiteConfigurationPermissions.VIEW_ALL_SITE_CONFIGS) > -1
      ? brandAppearanceRoutes
      : null,
    permissions?.findIndex((x) => x.id === SiteConfigurationPermissions.VIEW_ALL_SITE_CONFIGS) > -1
      ? brandBettingRoutes
      : null,
    permissions?.findIndex((x) => x.id === SiteConfigurationPermissions.VIEW_ALL_SITE_CONFIGS) > -1
      ? brandSportsbookRoutes
      : null,
    permissions?.findIndex((x) => x.id === SiteConfigurationPermissions.VIEW_ALL_SITE_CONFIGS) > -1
      ? brandResultRoutes
      : null,
    permissions?.findIndex((x) => x.id === SiteConfigurationPermissions.VIEW_ALL_SITE_CONFIGS) > -1
      ? brandAffiliateRoutes
      : null,
    permissions?.findIndex((x) => x.id === SiteConfigurationPermissions.VIEW_ALL_SITE_CONFIGS) > -1
      ? brandVirtualRoutes
      : null,
    // permissions?.findIndex(x => x.id === SiteConfigurationPermissions.VIEW_ALL_SITE_CONFIGS) > -1? brandCasinoRoutes : null,
    permissions?.findIndex((x) => x.id === SiteConfigurationPermissions.VIEW_ALL_SITE_CONFIGS) > -1
      ? brandPaymentRoutes
      : null,
    permissions?.findIndex((x) => x.id === SiteConfigurationPermissions.VIEW_ALL_SITE_CONFIGS) > -1
      ? brandMarketTypeRoutes
      : null,
    permissions?.findIndex((x) => x.id === WidgetPermissions.VIEW_ALL_WIDGETS) > -1 ? widgetMyRoutes : null,
    permissions?.findIndex((x) => x.id === AssetPermissions.VIEW_ALL_ASSETS) > -1 ? assetRoutes : null,
    permissions?.findIndex((x) => x.id === LayoutPermissions.VIEW_LAYOUTS) > -1 ? layoutRoutes : null,
  ].filter((x) => x);
