import axios from "axios";

import { logout } from "../slices/authSlice";

const getLocale = (language) => {
  switch (language) {
    case "en":
      return "en-GB";
    case "de":
      return "de-DE";
    case "es":
      return "es-ES";
    case "fr":
      return "fr-FR";
    case "in":
      return "in-ID";
    case "ja":
      return "ja-JP";
    case "ko":
      return "ko-KR";
    case "ms":
      return "ms-MY";
    case "pt":
      return "pt-PT";
    case "ru":
      return "ru-RU";
    case "th":
      return "th-TH";
    case "vi":
      return "vi-VN";
    case "zh":
      return "zh-CN";
    default:
      return "en-GB";
  }
};

const createAxiosInstance = (dispatch) => {
  const instance = axios.create();
  instance.defaults.headers["Content-Type"] = "application/json";

  // Add a request interceptor
  instance.interceptors.request.use(
    (config) => {
      // Actions before request is sent
      // Include the auth token, if any...
      const authToken = localStorage.getItem("authToken");
      if (authToken && typeof authToken !== "undefined") {
        config.headers["X-Token"] = authToken;
      }
      const language = localStorage.getItem("language");
      if (language && typeof language !== "undefined") {
        config.headers["Accept-Language"] = getLocale(language);
      }

      return config;
    },
    (error) =>
      // Do something with request error
      Promise.reject(error),
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      // if there is a token in the response - update our local storage token
      const newToken = response.headers["x-token"] || response.headers["X-Token"];
      if (newToken) {
        localStorage.setItem("authToken", newToken);
      }

      return response;
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      // If forbidden error received - assume inappropriate access or user kicked out after a token expired.
      // Clear the logged in state so the user can be safely sent to the login page
      if ([401, 403].includes(error?.response?.status)) {
        dispatch(logout());
      }

      return Promise.reject(error);
    },
  );

  return instance;
};

export default createAxiosInstance;
