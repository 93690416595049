import React from "react";
import Helmet from "react-helmet";

function Empty() {
  return (
    <>
      <Helmet title="Home" />
    </>
  );
}

export default Empty;
