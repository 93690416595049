import { configureStore } from "@reduxjs/toolkit";

import assetReducer from "./slices/assetSlice";
import authReducer from "./slices/authSlice";
import brandReducer from "./slices/brandSlice";
import countryReducer from "./slices/countrySlice";
import currencyReducer from "./slices/currencySlice";
import languageReducer from "./slices/languageSlice";
import layoutReducer from "./slices/layoutSlice";
import leagueReducer from "./slices/leagueSlice";
import marketTypeReducer from "./slices/marketTypeSlice";
import periodReducer from "./slices/periodSlice";
import permissionReducer from "./slices/permissionSlice";
import sdcReducer from "./slices/sdcSlice";
import siteConfigReducer from "./slices/siteConfigSlice";
import snackbarReducer from "./slices/snackbarSlice";
import sportReducer from "./slices/sportSlice";
import themeReducer from "./slices/themeSlice";
import widgetReducer from "./slices/widgetSlice";

const store = configureStore({
  reducer: {
    asset: assetReducer,
    auth: authReducer,
    brand: brandReducer,
    country: countryReducer,
    currency: currencyReducer,
    language: languageReducer,
    layout: layoutReducer,
    league: leagueReducer,
    marketType: marketTypeReducer,
    period: periodReducer,
    permission: permissionReducer,
    sdc: sdcReducer,
    siteConfig: siteConfigReducer,
    snackbar: snackbarReducer,
    sport: sportReducer,
    theme: themeReducer,
    widget: widgetReducer,
  },
});

export default store;
