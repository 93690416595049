import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";

export const getInitialState = (sports = null) => ({
  error: null,
  loading: false,
  sports,
});

export const getMarketTypesBySportCode = createAsyncThunk("marketTypes/loadAll", async ({ sportCode }, thunkAPI) => {
  try {
    const axios = createAxiosInstance(thunkAPI.dispatch);

    const result = await axios.get(`/cms/markettypes?sportCode=${sportCode}`);

    return {
      marketTypes: result.data,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to obtain the market type list", // serializable (err.response.data)
      name: "Market Type List Fetch Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

const marketTypeSlice = createSlice({
  extraReducers: {
    [getMarketTypesBySportCode.pending]: (state) => {
      state.error = null;
      state.loading = true;
      state.marketTypes = [];
    },
    [getMarketTypesBySportCode.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getMarketTypesBySportCode.fulfilled]: (state, action) => {
      state.error = null;
      state.loading = false;
      state.marketTypes = action.payload.marketTypes;
    },
  },
  initialState: getInitialState(),
  name: "marketType",
  reducers: {},
});

const { reducer } = marketTypeSlice;
export default reducer;
